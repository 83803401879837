import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  SessionExpiryWarning,
  SessionExpiryWarningRef,
} from '../../components/session-expiry';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { actions } from '../../redux/slices';
import styles from './style.module.less';
import { confirm } from '@law-connect/react-components';
import BinIcon from '../../assets/icons/bin.svg?react';
import RefreshIcon from '../../assets/icons/refresh.svg?react';

import { ChatElement, ChatElementRef } from './chat-element';
import { Header, MENU_HEADER_ID } from '../../components/header';
import selectors from '../../redux/selectors';
import {
  FileWithUpload,
  LegalCaseState,
  Message,
  MessageType,
  Prematter,
  Question,
} from '@law-connect/types';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/footer';
import { Wrapper } from '../../components/wrapper';
import { BackHeader } from '../../components/back-header';
import { GOOGLE_PRIVACY_POLICY, GOOGLE_TERMS } from '../../constants';
import { COOKIE_ID } from '../../components/cookie-consent';
import { useCookies } from 'react-cookie';
import AuthModal, {
  AuthModalType,
  LoginModalRef,
} from '../../components/auth-modal';
import env from '../../constants/env';
import ReCAPTCHA from 'react-google-recaptcha';
import { load as botdLoader } from '@fingerprintjs/botd';
import { toast } from 'react-toastify';
import { isPrematterComplete } from '../../utils/is-prematter-complete';
import * as Sentry from '@sentry/react';
import { addLanguage } from '../../i18n';
import ReactPixel from 'react-facebook-pixel';

const botdPromise = botdLoader({
  // We disable monitoring so that the botd library doesn't send user identifiable data to the server
  monitoring: false,
});

interface Props {}

export const MaxwellChat: FC<Props> = (props) => {
  const { isAuthenticated } = useKindeAuth();

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(undefined, { lng: wrappedLanguage });

  const [cookies, setCookie] = useCookies([COOKIE_ID]);

  const chatElementRef = useRef<ChatElementRef>(null);
  const headerContainerRef = useRef(null);

  const authModalRef = useRef<LoginModalRef | null>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [botDetected, setBotDetected] = useState<boolean>(false);

  const [disabled, setDisabled] = useState<boolean>(false);
  const userPrematter = useAppSelector(selectors.prematter.getById(id));
  const prematter = useAppSelector(selectors.session.getPrematter());
  const previousPrematter = React.useRef<Prematter | null>(null);
  const user = useAppSelector(selectors.user.getUser());
  const messages: Message[] = useAppSelector(
    selectors.session.getMessages()
  ) as Message[];

  const isAwaitingReply = useAppSelector(selectors.session.isAwaitingReply());
  const isAddQuestionPending = useAppSelector(
    selectors.session.isAddQuestionPending()
  );
  const addQuestionError = useAppSelector(
    selectors.session.getAddQuestionError()
  );
  const isFileUploading = useAppSelector(selectors.session.isFileUploading());
  const files = useAppSelector(selectors.file.getPrematterFiles(prematter?.id));

  const sessionExpiryRef = useRef<SessionExpiryWarningRef>();

  const [bottomOffset, setBottomOffset] = useState<number>(0);

  const privacyText: string = useMemo(() => {
    return t('auth.google-privacy-terms')
      .replace(/GOOGLE_TERMS/g, GOOGLE_TERMS)
      .replace(/GOOGLE_PRIVACY_POLICY/g, GOOGLE_PRIVACY_POLICY);
  }, [t]);

  // On page resize, update the bottom offset
  useEffect(() => {
    const handleResize = () => {
      if (!cookies[COOKIE_ID]) {
        setBottomOffset(document.getElementById(COOKIE_ID)?.offsetHeight || 0);
      } else {
        setBottomOffset(0);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!cookies[COOKIE_ID]) {
      setBottomOffset(document.getElementById(COOKIE_ID)?.offsetHeight || 0);
    } else {
      setBottomOffset(0);
    }
  }, [cookies]);

  useEffect(() => {
    if (id && userPrematter && userPrematter.id === id) {
      dispatch(actions.session.setPrematter({ prematter: userPrematter }));
    }
  }, [id, userPrematter]);

  useEffect(() => {
    const headerElement = document.getElementById(MENU_HEADER_ID);

    if (headerElement) {
      headerContainerRef.current = headerElement;
    }
  }, []);

  const resetChat = useCallback(() => {
    dispatch(actions.session.delete());
    if (disabled) {
      setDisabled(false);
    }
    navigate(location.pathname, { replace: true, state: {} });
  }, [disabled, dispatch, location.pathname, navigate]);

  const refreshSession = useCallback(() => {
    dispatch(actions.session.fetch());
    if (disabled) {
      setDisabled(false);
    }
  }, [disabled, dispatch]);

  const disableChat = () => {
    setDisabled(true);
  };

  const deleteChat = useCallback(async () => {
    if (isAuthenticated) {
      await dispatch(actions.session.delete());
      await dispatch(actions.prematter.delete({ id: prematter?.id || null }));
      navigate('/account');
    } else {
      dispatch(actions.session.delete());
      navigate('/');
    }
  }, [dispatch, isAuthenticated, prematter, navigate]);

  const onSendMessage = useCallback(
    async (args: { type: MessageType; text?: string; files?: File[] }) => {
      if (args?.files?.length || args?.text?.trim()?.length) {
        try {
          await dispatch(actions.session.sendMessage(args)).unwrap();
        } catch (e) {
          chatElementRef.current?.setMessage(args.text);
          if (typeof e === 'string') {
            toast.error(e);
          } else {
            toast.error(t('chat.error-message'));
          }
        }
      }
      sessionExpiryRef.current?.resetTimeLeft();
    },
    [dispatch, t]
  );

  const addQuestion = (args: { question: string }) => {
    dispatch(actions.session.addQuestion(args));
  };

  const onFormCompletion = (questions: Question[]) => {
    dispatch(
      actions.session.sendForm({
        form: {
          ...prematter.context.form,
          questions,
        },
      })
    );
  };

  const onFileUpload = (files: FileWithUpload[]) => {
    files.forEach((file) => {
      dispatch(
        actions.file.upload({
          prematterId: prematter.id,
          file,
        })
      );
    });
  };

  const onComplete = async () => {
    if (isAuthenticated) {
      const navigateTo = `/case/${prematter?.id}`;
      dispatch(actions.session.delete());
      if (id) {
        await dispatch(actions.prematter.fetchById({ id }));
      }
      navigate(navigateTo);
    } else {
      console.log('open auth');
      authModalRef.current.openModal();
    }
  };

  const onBotAuthClose = useCallback(() => {
    dispatch(actions.session.reset());
    dispatch(actions.websocket.deleteSession());
  }, [dispatch]);

  useEffect(() => {
    if (!sessionExpiryRef?.current?.ref?.current) {
      return;
    }
    sessionExpiryRef.current.ref.current.style.position = 'fixed';
    // eslint-disable-next-line @stylistic/js/max-len
    sessionExpiryRef.current.ref.current.style.top = `${
      document.getElementById(MENU_HEADER_ID)?.offsetHeight + 20
    }px`;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!sessionExpiryRef?.current?.ref?.current) {
        return;
      }
      sessionExpiryRef.current.ref.current.style.position = 'fixed';
      // eslint-disable-next-line @stylistic/js/max-len
      sessionExpiryRef.current.ref.current.style.top = `${
        document.getElementById(MENU_HEADER_ID)?.offsetHeight + 20
      }px`;
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.newCase) {
      const startNewCase = async () => {
        // Clear the current session (this will also start a new one)
        await dispatch(actions.session.delete());
        dispatch(actions.file.clearErrors());
        if (location?.state?.message) {
          chatElementRef.current?.setMessage(location?.state?.message);
          onSendMessage({
            type: MessageType.Chat,
            text: location?.state?.message,
          });
        }
        navigate(location.pathname, { replace: true, state: {} });
      };
      startNewCase();
    }
  }, []);

  useEffect(() => {
    if (
      !location?.state?.newCase &&
      prematter &&
      [
        LegalCaseState.PreparingQuestions,
        LegalCaseState.PreparingForm,
      ].includes(prematter.state)
    ) {
      const intervalId = setInterval(() => {
        dispatch(actions.session.fetch());
      }, 2000);

      // Cleanup interval on component unmount or when conditions change
      return () => clearInterval(intervalId);
    }
  }, [dispatch, location?.state?.newCase, prematter]);

  useEffect(() => {
    if (!location?.state?.newCase && prematter?.id) {
      dispatch(actions.file.fetchByPrematterId({ prematterId: prematter.id }));
    }
  }, [dispatch, location?.state?.newCase, prematter?.id]);

  useEffect(() => {
    if (prematter?.id) {
      Sentry.setTag('prematterId', prematter.id);
    }
  }, [prematter]);

  useEffect(() => {
    if (
      !previousPrematter.current &&
      prematter?.id &&
      !isPrematterComplete(prematter.state)
    ) {
      previousPrematter.current = prematter as Prematter;
      ReactPixel.track('StartedChat', { language: prematter.language });
    } else if (
      previousPrematter.current &&
      prematter?.state === LegalCaseState.Form
    ) {
      previousPrematter.current = prematter as Prematter;
      // eslint-disable-next-line @stylistic/js/max-len
      ReactPixel.track('ViewedForm', { matterTypes: prematter.context?.matterTypes, location: prematter.context?.location });
    } else if (
      previousPrematter.current?.state === LegalCaseState.PreparingReport &&
      prematter?.state === LegalCaseState.ReportReady
    ) {
      previousPrematter.current = prematter as Prematter;
      ReactPixel.track('ViewedReport', { id: prematter.id });
    }
  }, [prematter]);

  useEffect(() => {
    const checkIfBot = async () => {
      if (!isAuthenticated) {
        try {
          const result = await botdPromise
            .then((botd) => botd.detect())
            .then((result) => result)
            .catch((e) => {
              return { bot: false };
            });
          if (result.bot) {
            // Bot detected
            authModalRef.current.openModal();
            return;
          }
          if (recaptchaRef.current) {
            // Send to server to check for bot
            const token = await recaptchaRef.current.executeAsync();

            try {
              const result = await dispatch(
                actions.session.recaptcha({
                  token,
                })
              ).unwrap();
              if (result.isBot) {
                setBotDetected(true);
                setDisabled(true);
                authModalRef.current.openModal();
              } else {
                setBotDetected(false);
              }
            } catch (e) {
              setBotDetected(false);
              console.error('failed bot check', e);
            }
          }
        } catch (e) {
          setBotDetected(false);
          console.error(e);
        }
      }
    };
    checkIfBot();
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (prematter?.language) {
      const asyncWrapper = async () => {
        const wrapped = await addLanguage(prematter.language);
        setWrappedLanguage(wrapped);
      };
      asyncWrapper();
    }
  }, [prematter?.language]);

  // if (location?.state?.newCase) {
  //   return null;
  // }

  return (
    <>
      <Header />

      <Wrapper
        className={styles.maxwellWrapper}
        contentClassName={styles.maxwellContent}
      >
        {!isAuthenticated ? (
          <SessionExpiryWarning
            ref={sessionExpiryRef}
            resetSession={resetChat}
            refreshSession={refreshSession}
            disableChat={disableChat}
          />
        ) : null}
        <BackHeader
          backOverride={
            !isPrematterComplete(prematter?.state)
              ? () => navigate('/account')
              : undefined
          }
          items={
            location?.state?.newCase
              ? []
              : [
                  ...(prematter?.userId
                    ? []
                    : [
                        {
                          label: t('confirm.reset'),
                          onClick: () =>
                            confirm({
                              title: t('confirm.reset'),
                              description: t(
                                'account.delete-case-confirmation'
                              ),
                              onConfirm: () => resetChat(),
                            }),
                          icon: <RefreshIcon />,
                        },
                      ]),
                  {
                    label: t('confirm.delete'),
                    onClick: () =>
                      confirm({
                        title: t('confirm.delete'),
                        description: t('account.delete-case-confirmation'),
                        onConfirm: () => deleteChat(),
                      }),
                    icon: <BinIcon />,
                  },
                ]
          }
        />

        <ChatElement
          ref={chatElementRef}
          prematter={prematter as Prematter}
          bottomOffset={bottomOffset ? bottomOffset : undefined}
          messages={
            location?.state?.newCase ||
            !messages ||
            messages.length === 0 ||
            (messages.length === 1 && messages[0].id === 'new-message')
              ? [
                  {
                    id: '1',
                    type: MessageType.Chat,
                    from: 'lawyer',
                    content: t('chat.helloMessage'),
                    createdAt: Date.now(),
                  },
                  ...(location?.state?.newCase ? [] : messages),
                ]
              : location?.state?.newCase
              ? []
              : messages
          }
          onSendMessage={onSendMessage}
          isLoading={isAwaitingReply}
          addQuestion={addQuestion}
          addQuestionPending={isAddQuestionPending}
          addQuestionError={addQuestionError}
          isFileUploading={isFileUploading}
          onFormFileUpload={onFileUpload}
          onFormCompletion={onFormCompletion}
          files={files}
          onComplete={onComplete}
          disabled={disabled || isAwaitingReply}
          headerContainerRef={headerContainerRef}
          defaultAiLawyer={user?.aiLawyer}
        />
      </Wrapper>

      <div
        className={styles.maxwellRecaptcha}
        dangerouslySetInnerHTML={{ __html: privacyText }}
      />
      <Footer className={styles.maxwellFooter} />

      <ReCAPTCHA
        ref={recaptchaRef}
        size='invisible'
        sitekey={env.RECAPTCHA_SITE_KEY}
      />

      <AuthModal
        ref={authModalRef}
        type={
          botDetected
            ? AuthModalType.BOT_AUTH
            : isAuthenticated
            ? AuthModalType.AUTH
            : AuthModalType.REPORT
        }
        onClose={botDetected ? onBotAuthClose : undefined}
        redirectTo={prematter ? `/case/${prematter.id}` : '/account'}
      />
    </>
  );
};
